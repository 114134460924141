import React from "react"
import { useSetRecoilState } from "recoil"
import { callbackModalOpen } from "./atoms"

function PriceActions() {
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)
  return (
    <div className="price-actions">
      <button
        onClick={() => setCallbackModalOpen(true)}
        className="button button--o-orange button--shadow"
      >
        Узнать точную цену
      </button>
      <button
        onClick={() => setCallbackModalOpen(true)}
        className="button button--solid-grad button--shadow"
      >
        Сдать катализатор
      </button>
    </div>
  )
}

export default PriceActions
