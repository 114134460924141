import img1 from "../../assets/img/catalog/mitsubishi-lancer-x.jpg"
import img2 from "../../assets/img/catalog/kia-rio-xline.jpg"
import img3 from "../../assets/img/catalog/renault-megane.jpg"
import img4 from "../../assets/img/catalog/renault-duster.jpg"
import img5 from "../../assets/img/catalog/bmw-m5-f90.jpg"
import img6 from "../../assets/img/catalog/opel-corsa.jpg"
import img7 from "../../assets/img/catalog/Suzuki Grand Vitara.jpg"
import img8 from "../../assets/img/catalog/Porsche Cayenne.jpg"
import img9 from "../../assets/img/catalog/BMW X5M.jpg"

import img10 from "../../assets/img/catalog/Kia Rio.jpg"
import img11 from "../../assets/img/catalog/Kia Ceed.jpg"
import img12 from "../../assets/img/catalog/Lada Kalina.jpg"
import img13 from "../../assets/img/catalog/Niva Chevrolet.png"
import img14 from "../../assets/img/catalog/Iveco Daily.jpg"
import img15 from "../../assets/img/catalog/mazda 6.png"
import img16 from "../../assets/img/catalog/SEAT Ibiza.png"
import img17 from "../../assets/img/catalog/Hyundai Tucson.jpg"
import img18 from "../../assets/img/catalog/Chevrolet Niva.jpg"

import img19 from "../../assets/img/catalog/Honda Civic.png"
import img20 from "../../assets/img/catalog/Pontiac Vibe.jpg"
import img21 from "../../assets/img/catalog/Volkswagen Polo.png"
import img22 from "../../assets/img/catalog/hyundai tucson.png"
import img23 from "../../assets/img/catalog/Geely MK.jpg"
import img24 from "../../assets/img/catalog/kia serato.png"
import img25 from "../../assets/img/catalog/Kia Ceed.png"
import img26 from "../../assets/img/catalog/Mitsubishi Outlander.jpg"
import img27 from "../../assets/img/catalog/Chevrolet Lacetti.jpg"

import img28 from "../../assets/img/catalog/PEUGEOT 307.jpg"
import img29 from "../../assets/img/catalog/Hyundai Solaris.jpg"
import img30 from "../../assets/img/catalog/TOYOTA PRADO 120.jpg"

export const ceramicCars = [
  {
    link: "/keramicheskie-katalizatoryi/katalizator-mitsubishi-lancer-x",
    name: "Mitsubishi Lancer X",
    year: "2011",
    engine: "1.8",
    type: "бензин",
    img: img1,
    price: "20 900",
    date: "01.12.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-kia-rio-x-line",
    name: "Kia Rio X-Line",
    year: "2019",
    engine: "1.6",
    type: "бензин",
    img: img2,
    price: "17 600",
    date: "22.11.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-renault-megane",
    name: "Renault Megane",
    year: "2009",
    engine: "1.6",
    type: "бензин",
    img: img3,
    price: "9 300",
    date: "12.11.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-renault-duster",
    name: "Renault Duster",
    year: "2012",
    engine: "2.0",
    type: "бензин",
    img: img4,
    price: "17 500",
    date: "08.11.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-bmw-m5",
    name: "BMW M5",
    year: "2020",
    engine: "4.4",
    type: "бензин",
    img: img5,
    price: "67 000",
    date: "27.10.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-opel-corsa",
    name: "Opel Corsa",
    year: "2004",
    engine: "1.2",
    type: "бензин",
    img: img6,
    price: "10 800",
    date: "22.10.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-suzuki-grand-vitara",
    name: "Suzuki Grand Vitara",
    year: "2004",
    engine: "2.0",
    type: "бензин",
    img: img7,
    price: "10 400",
    date: "15.10.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-porsche-cayenne",
    name: "Porsche Cayenne",
    year: "2008",
    engine: "4.8",
    type: "бензин",
    img: img8,
    price: "41 500",
    date: " 12.10.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-bmw-x5m",
    name: "BMW X5M",
    year: "2018",
    engine: "4.4",
    type: "бензин",
    img: img9,
    price: "49 300",
    date: "11.10.2021",
  },
]

export const ceramicCars2 = [
  {
    link: "/keramicheskie-katalizatoryi/katalizator-kia-rio",
    name: "Kia Rio",
    year: "2017",
    engine: "1.6",
    type: "бензин",
    img: img10,
    price: "19 100",
    date: "11.10.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-kia-ceed-2014",
    name: "Kia Ceed",
    year: "2014",
    engine: "1.4",
    type: "бензин",
    img: img11,
    price: "12 200",
    date: "29.09.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-lada-kalina",
    name: "Lada Kalina",
    year: "2014",
    engine: "1.6",
    type: "бензин",
    img: img12,
    price: "3 400",
    date: "29.09.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-chevrolet-niva-2013",
    name: "Niva Chevrolet",
    year: "2013",
    engine: "1.7",
    type: "бензин",
    img: img13,
    price: "5 460",
    date: "29.09.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-iveco-daily",
    name: "Iveco Daily",
    year: "2009",
    engine: "3.0",
    type: "бензин",
    img: img14,
    price: "9 240",
    date: "10.09.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-mazda-6",
    name: "Mazda 6",
    year: "2009",
    engine: "2.0",
    type: "бензин",
    img: img15,
    price: "12 540",
    date: "06.09.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-seat-ibiza",
    name: "SEAT Ibiza",
    year: "2010",
    engine: "1.4",
    type: "бензин",
    img: img16,
    price: "12 450",
    date: "30.08.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-hyundai-tucson-2018",
    name: "Hyundai Tucson",
    year: "2018",
    engine: "2.0",
    type: "бензин",
    img: img17,
    price: "11 300",
    date: "27.08.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-chevrolet-niva",
    name: "Chevrolet Niva",
    year: "2013",
    engine: "1.7",
    type: "бензин",
    img: img18,
    price: "6 700",
    date: "23.08.2021",
  },
]

export const ceramicCars3 = [
  {
    link: "/keramicheskie-katalizatoryi/katalizator-honda-civic",
    name: "Honda Civic",
    year: "2009",
    engine: "2.0",
    type: "бензин",
    img: img19,
    price: "14 700",
    date: "20.08.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-pontiac-vibe",
    name: "Pontiac Vibe",
    year: "2005",
    engine: "1.8",
    type: "бензин",
    img: img20,
    price: "9 200",
    date: "06.08.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-volkswagen-polo",
    name: "Volkswagen Polo",
    year: "2019",
    engine: "1.4 (turbo)",
    type: "бензин",
    img: img21,
    price: "12 480",
    date: "28.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-hyundai-tucson-2008",
    name: "Hyundai Tucson",
    year: "2008",
    engine: "2.0",
    type: "бензин",
    img: img22,
    price: "18 000",
    date: "26.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-geely-mk",
    name: "Geely MK",
    year: "2013",
    engine: "1.5",
    type: "бензин",
    img: img23,
    price: "6 000",
    date: "23.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-kia-cerato",
    name: "Kia Cerato",
    year: "2012",
    engine: "1.6",
    type: "бензин",
    img: img24,
    price: "15 000",
    date: "16.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-kia-ceed",
    name: "Kia Ceed",
    year: "2018",
    engine: "2.0",
    type: "бензин",
    img: img25,
    price: "25 000",
    date: "15.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-mitsubishi-outlander",
    name: "Mitsubishi Outlander",
    year: "2018",
    engine: "2.0",
    type: "бензин",
    img: img26,
    price: "18 000",
    date: "09.07.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-chevrolet-lacetti",
    name: "Chevrolet Lacetti",
    year: "2012",
    engine: "1.4",
    type: "бензин",
    img: img27,
    price: "15 000",
    date: "19.05.2021",
  },
]

export const ceramicCars4 = [
  {
    link: "/keramicheskie-katalizatoryi/katalizator-ot-peugeot-307",
    name: "PEUGEOT 307",
    year: "2004",
    engine: "2.0",
    type: "бензин",
    img: img28,
    price: "15 000",
    date: "13.05.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-hyundai-solaris",
    name: "Hyundai Solaris",
    year: "2015",
    engine: "1.6",
    type: "бензин",
    img: img29,
    price: "19 000",
    date: "09.04.2021",
  },
  {
    link: "/keramicheskie-katalizatoryi/katalizator-toyota-prado-120",
    name: "TOYOTA PRADO 120",
    year: "2006",
    engine: "4.0",
    type: "бензин",
    img: img30,
    price: "35 000",
    date: "16.02.2021",
  },
]
